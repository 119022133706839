import React from 'react';
import './css/App.css';
import AudioProcessor from './components/AudioProcessor';
import Header from './components/Header';
import About from './pages/About';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';


function App() {
  return (
    <Router>
      <div className="App">
        <Header />
        <Routes>
          <Route path="/" element={<AudioProcessor />} />
          <Route path="/about" element={<About />} />
        </Routes>
      </div>
    </Router>
  );
}

export default App;
