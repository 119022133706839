import React from 'react';
import { Link, useLocation } from 'react-router-dom';
import '../css/App.css';

function Header() {
    const location = useLocation();

    return (
        <header className="App-header">
            <nav>
                <ul>
                    {location.pathname === '/about' ? (
                        <li className="title">
                            <Link to="/">ONLINE - AMP</Link>
                        </li>
                    ) : (
                        <li className="title">
                            <Link to="/">ONLINE - AMP</Link>
                        </li>
                    )}
                    {location.pathname !== '/about' ? (
                        <li className="about-link">
                            <Link to="/about">About</Link>
                        </li>
                    ) : (
                        <li className="about-link">
                            <Link to="/">Home</Link>
                        </li>
                    )}
                    
                </ul>
            </nav>
        </header>
    );
}

export default Header;
