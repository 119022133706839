import React from 'react';

const About = () => {
  return (
    <div className="about">
      <h1>About online-amp.com</h1>
      <p>Welcome to online-amp.com, your go-to platform for efficient, high-quality online audio processing. Designed with user experience and sound quality at the forefront, we provide an unmatched audio processing experience accessible to both professionals and enthusiasts alike.</p>

      <h2>Who we are</h2>
      <p>We are a dedicated and diverse team of student veterans. We know service - and it is our mission to provide each and every user with a 
        high-quality, efficient, and secure audio processing experience. We are committed to ensuring that our platform is accessible to all,
        and we are constantly working to improve our platform to meet the needs of our users. We are proud to serve you, and we thank you for
        choosing online-amp.com.
      </p>

      <h2>Privacy and Data Security</h2>
      <p>We respect your privacy and prioritize data security. online-amp.com operates with a strict no-data-storage policy. Every sound or audio file processed on our platform is solely controlled by the user — we don’t store any data, ensuring your privacy and data security.</p>

      <h2>Ad Support</h2>
      <p>To keep this platform free and accessible to all, online-amp.com is supported by Google AdSense. Ads are meticulously curated to ensure they are relevant and non-intrusive, guaranteeing an undisturbed and smooth user experience.</p>

      <h2>How The Site Works</h2>
      <p>This platform utilizes the power of <a href="https://reactjs.org/" target="_blank" rel="noopener noreferrer">React</a> for the user interface and <a href="https://tonejs.github.io/" target="_blank" rel="noopener noreferrer">Tone.js</a> for real-time audio processing. The working mechanism is simple yet powerful:</p>
      <ol>
        <li><strong>Select an Input Device:</strong> The app retrieves all available audio input devices. Users can select their preferred option from a dropdown menu.</li>
        <li><strong>Adjust the Settings:</strong> Users can manipulate the audio in real-time by adjusting the gain, applying effects like distortion, reverb, and delay, or altering the compressor threshold as needed. The app provides intuitive sliders and checkboxes for these adjustments.</li>
        <li><strong>Record:</strong> Users can start recording the processed audio by clicking the "Record" button. The app captures the audio in real-time using Tone.js's Recorder class.</li>
        <li><strong>Download:</strong> After stopping the recording, a download link is generated for the user to easily download the audio file, ensuring user's privacy as no audio files are stored on the server.</li>
      </ol>
      <p>All audio processing happens in the browser, ensuring users' privacy and offering real-time feedback. For developers interested, the source code is structured and commented for easy understanding, facilitating further customization and enhancements.</p>

      <h2>Contact Us</h2>
      <p>If you have any inquiries or need further information about our online audio processing platform, feel free to <a href="mailto:joey@thompsonswe.com">contact us</a>. We are always ready to assist and ensure your experience on online-amp.com is exceptional.</p>
    </div>
  );
}

export default About;
