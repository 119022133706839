import React, { useEffect, useState, useRef } from 'react';
import * as Tone from 'tone';

const AudioProcessor = () => {
  const [devices, setDevices] = useState([]);
  const [inputDevice, setInputDevice] = useState('');
  const [gain, setGain] = useState(0.5);
  const [isDistorted, setIsDistorted] = useState(false);
  const [reverbLevel, setReverbLevel] = useState(0);
  const [delayTime, setDelayTime] = useState(0);
  const [compressorThreshold, setCompressorThreshold] = useState(-24);
  const [distortionValue, setDistortionValue] = useState(0.2); // Added this state variable

  const [isRecording, setIsRecording] = useState(false); 
  const recorder = useRef(new Tone.Recorder()); 

  const volume = useRef(new Tone.Volume());
  const distortion = useRef(new Tone.Distortion());
  const reverb = useRef(new Tone.Reverb());
  const delay = useRef(new Tone.FeedbackDelay());
  const source = useRef(null);
  const compressor = useRef(new Tone.Compressor()); 

  const resetAudioQuality = () => {
    window.location.reload();
  };

  const handleDistortionValueChange = (e) => {  // Added this handler
    const value = parseFloat(e.target.value);
    setDistortionValue(value);
    distortion.current.distortion = value;
  };

  useEffect(() => {
    // Update the distortion value dynamically as it is changed
    if (isDistorted) {
      distortion.current.distortion = distortionValue;
    }
  }, [distortionValue, isDistorted]);

  useEffect(() => {
    const enumerateDevices = async () => {
      try {
        const stream = await navigator.mediaDevices.getUserMedia({ audio: true });
        stream.getTracks().forEach(track => track.stop());

        const devices = await navigator.mediaDevices.enumerateDevices();
        setDevices(devices.filter(device => device.kind === 'audioinput'));
      } catch (error) {
        console.error('Error getting user media:', error);
      }
    };

    enumerateDevices();
  }, []);

  useEffect(() => {
    if (inputDevice === '') return;
  
    const setupAudio = async () => {
      await Tone.start();
  
      source.current = new Tone.UserMedia();
      await source.current.open(inputDevice);
  
      source.current.fan(volume.current, recorder.current); 
      source.current.chain(volume.current, distortion.current, compressor.current, delay.current, reverb.current, Tone.Destination);
  
      // Update the effects dynamically as they are changed
      volume.current.volume.value = 20 * Math.log10(gain);
      distortion.current.wet.value = isDistorted ? 1 : 0;
      reverb.current.wet.value = reverbLevel;
      delay.current.wet.value = delayTime;
      compressor.current.threshold.value = compressorThreshold;
    };
  
    setupAudio();
  
    return () => {
      if (source.current) source.current.disconnect();
    };
  }, [inputDevice, gain, isDistorted, reverbLevel, delayTime, compressorThreshold]);
  

  const handleGainChange = (e) => setGain(parseFloat(e.target.value));
  const handleReverbChange = (e) => setReverbLevel(parseFloat(e.target.value));
  const handleDelayChange = (e) => setDelayTime(parseFloat(e.target.value));
  const handleCompressorThresholdChange = (e) => setCompressorThreshold(parseFloat(e.target.value)); // New handler for compressor threshold

  const toggleDistortion = () => setIsDistorted(prev => !prev);

  const toggleRecording = async () => { 
    if (isRecording) {
      const recording = await recorder.current.stop();
      const url = URL.createObjectURL(recording);
      const a = document.createElement('a');
      a.style.display = 'none';
      a.href = url;
      a.download = 'online-amp.wav'; 
      document.body.appendChild(a);
      a.click();
      window.URL.revokeObjectURL(url);
    } else {
      await Tone.start(); 
      recorder.current.start();
    }

    setIsRecording(!isRecording);
  };

  return (
    <div>
      <label>
        Input Device:
        <select value={inputDevice} onChange={(e) => setInputDevice(e.target.value)}>
          <option value="">Select an input device</option>
          {devices.map(device => (
            <option key={device.deviceId} value={device.deviceId}>{device.label || 'Unknown Device'}</option>
          ))}
        </select>
      </label>

      <label>
        Gain:
        <input type="range" min="0" max="1" step="0.01" value={gain} onChange={handleGainChange} />
      </label>

      <label>
        Compressor Threshold:
        <input type="range" min="-100" max="0" step="1" value={compressorThreshold} onChange={handleCompressorThresholdChange} />
      </label>

      <label>
        Reverb:
        <input type="range" min="0" max="1" step="0.01" value={reverbLevel} onChange={handleReverbChange} />
      </label>

      <label>
        Delay:
        <input type="range" min="0" max="1" step="0.01" value={delayTime} onChange={handleDelayChange} />
      </label>

      <label>
        Distortion:
        <input type="checkbox" checked={isDistorted} onChange={toggleDistortion} />
      </label>

      {/* This is conditionally rendered based on whether isDistorted is true */}
      {isDistorted && (
        <label>
          Distortion Value:
          <input 
            type="range" 
            min="0.1" 
            max="0.9" 
            step="0.01" 
            value={distortionValue} 
            onChange={handleDistortionValueChange} 
          />
        </label>
      )}

      <button onClick={toggleRecording}>
        {isRecording ? 'Stop' : 'Record'}
      </button>
      <div className="audio-warning"> {/* Added this div to display the message */}
      <button onClick={resetAudioQuality}>Reset Audio Quality</button>
          <p>
            Note: The more you adjust the sliders, the worse the audio quality might become. 
            If needed, refresh the page or click the "Reset Audio Quality" button to restore the original settings.
          </p>
        </div>
    </div>

    
  );
};

export default AudioProcessor;
